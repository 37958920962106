import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="section-head">
            <h2>Get in touch</h2>
          </div>
          <p className="lead  text-center">
            Have a query? Write to us.
            <span role="img" aria-label="backhand index pointing down">
              👇🏽
            </span>
          </p>
          <div
            className="contact row justify-content-center text-center"
            id="Contact"
          >
            <div className="col-lg-6">
              <form
                name="contact"
                method="post"
                data-netlify="true"
                netlify-honeypot="bot-field"
                action="/success"
              >
                <input
                  style={{ display: "none" }}
                  name="bot-field"
                  placeholder="dont fill this if you're a human"
                />

                <div>
                  <input
                    type="text"
                    name="name"
                    required
                    className="shadow-sm rounded"
                    placeholder="Your Name"
                    style={{ height: "50px" }}
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    required
                    className="shadow-sm rounded"
                    placeholder="Your Email"
                    style={{ height: "50px" }}
                  />
                </div>
                <div>
                  <textarea
                    name="message"
                    required
                    className="shadow-sm rounded"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div>
                  <button type="submit" className="shadow">
                    <span>Send Message</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
