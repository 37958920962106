import React, { Component } from "react";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";

export default class Banner extends Component {
  render() {
    const { data } = this.props;
    return (
      <BackgroundImage
        fluid={data.bannerImage.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
      >
        <div className="banner" id="About">
          <div className="container">
            <div className="banner-details">
              <p className="display-1 font-weight-bold text-uppercase">
                {data.title}
              </p>
              <ul className="pl-5 text-center list-inline">
                {data.bannerList.map((item, index) => {
                  return (
                    <li key={index} className="list-inline-item">
                      * {item}
                    </li>
                  );
                })}
              </ul>
              <Link
                to="/products"
                className="btn px-5 py-3 text-white mt-3 mt-sm-0"
                style={{ borderRadius: "30px", backgroundColor: "#0085AD" }}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </BackgroundImage>
    );
  }
}
