import React, { Component } from "react";
import Img from "gatsby-image";
import Slider from "react-slick";
import { Link } from "gatsby";

var settings = {
  dots: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

export default class Products extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="products section" id="Products">
        <div className="container">
          <div className="section-head">
            <h2>Featured Products</h2>
          </div>
          <Slider {...settings}>
            {data.edges.map((item, index) => {
              return (
                <div className="product-container mb-5" key={index}>
                  <p className="  text-left m-0">
                    <span className="product-img-text">
                      {item.node.category}
                    </span>
                  </p>
                  <Img
                    fluid={item.node.p1FeatureImage.fluid}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    className="product-img mb-3"
                  />

                  <Link
                    className="link lead font-weight-bold  "
                    to={`/` + item.node.slug}
                    style={{ textAlign: "left" }}
                  >
                    {item.node.title}
                  </Link>

                  {/* <p >{item.node.excerpt}</p> */}
                </div>
              );
            })}
          </Slider>

          <div className="see-more">
            <Link to="/products">
              <span>All Products</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
