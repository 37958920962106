import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import Service from "../components/service";
import Products from "../components/products";
import Testimonial from "../components/testimonial";
import Contact from "../components/contact";
import Photos from "../components/photos";
import SubForm from "../components/subform";

const HomePage = ({ data }) => (
  <Layout header="home">
    <SEO
      title={data.contentfulAboutSection.title}
      keywords={[
        `WTW `,
        `WTW Analytics`,
        `wtwanalytics.de`,
        `wtwanalytics`,
        `WTW de`,
        `Wastewater treatment`,
        `systems lab`,
        `water analysis`,
        ` quality online measurement technology`,
        `water analysis WTW`,
        `Lab laboratory`,
        `lab`,
        `On-Line`,
        `Online Process`,
        `IDS Wireless`,
        `IQSN`,
        `IQ SENSOR NET`,
        `Photometer`,
        `OxiTop`,
        `MultiLine`,
        `inoLab`,
        `photoLab `,
        `${data.contentfulAboutSection.title}`,
      ]}
    />
    <Banner data={data.contentfulAboutSection}></Banner>

    {data.contentfulSiteInformation.menus
      .filter((item) => item === "Service")
      .map((t) => {
        return <Service key="Service" data={data.contentfulService}></Service>;
      })}

    {data.contentfulSiteInformation.menus
      .filter((item) => item === "Products")
      .map((t) => {
        return (
          <Products key="Products" data={data.allContentfulProducts}></Products>
        );
      })}

    {data.contentfulSiteInformation.menus
      .filter((item) => item === "Testimonials")
      .map((t) => {
        return (
          <Testimonial
            key="Testimonial"
            data={data.allContentfulTestimonials}
          ></Testimonial>
        );
      })}
    <SubForm />
    {data.contentfulSiteInformation.menus
      .filter((item) => item === "Photos")
      .map((t) => {
        return <Photos key="Photos" data={data.contentfulPhotos}></Photos>;
      })}

    {data.contentfulSiteInformation.menus
      .filter((item) => item === "Contact")
      .map((t) => {
        return (
          <Contact
            key="Contact"
            data={data.contentfulAboutSection.email}
          ></Contact>
        );
      })}
  </Layout>
);

export default HomePage;

export const pageQuery = graphql`
  query AboutQuery {
    contentfulAboutSection {
      title
      message
      email
      location
      description {
        childMarkdownRemark {
          html
        }
      }
      bannerImage {
        fluid(quality: 100, maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      bannerList
    }
    contentfulService {
      title
      serv1Title
      serv2Title
      serv1Desc {
        childMarkdownRemark {
          html
        }
      }
      serv1Img {
        fluid(quality: 100, maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      serv2Desc {
        childMarkdownRemark {
          html
        }
      }
      serv2Img {
        fluid(quality: 100, maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    allContentfulProducts(limit: 5, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          category
          p1FeatureImage {
            fluid(maxWidth: 600) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }

          createdAt
        }
      }
    }
    allContentfulTestimonials {
      edges {
        node {
          name
          subTitle
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    contentfulPhotos {
      photos {
        fluid(maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    contentfulSiteInformation {
      menus
    }
  }
`;
