import React, { Component } from "react";
import Img from "gatsby-image";

export default class Service extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="service section" id="Service">
        <div className="container mb-5">
          <div className="section-head">
            <h2>{data.title}</h2>
          </div>
          <div className="row ">
            <div className="col-md-6 p-4 p-sm-5 order-2 order-sm-1">
              <h1 className="h2 mb-4" style={{ fontWeight: 600 }}>
                {data.serv1Title}
              </h1>
              <div
                className="text-secondary"
                style={{ lineHeight: 2 }}
                dangerouslySetInnerHTML={{
                  __html: data.serv1Desc.childMarkdownRemark.html,
                }}
              />
            </div>
            <div className="col-md-6 p-0 text-center order-1 order-sm-2">
              <Img
                fluid={data.serv1Img.fluid}
                className="w-100 "
                alt={data.serv1Title}
                style={{ height: "100%" }}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 p-0 text-center">
              <Img
                fluid={data.serv2Img.fluid}
                className="w-100 mb-5"
                alt={data.serv2Title}
                style={{ height: "100%" }}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
            <div className="col-md-6 p-4 p-sm-5">
              <h1 className="h2 mb-4" style={{ fontWeight: 600 }}>
                {data.serv2Title}
              </h1>
              <div
                className="text-secondary"
                style={{ lineHeight: 2 }}
                dangerouslySetInnerHTML={{
                  __html: data.serv2Desc.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
